import React, { useState } from "react";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { BsCheck2Circle } from "react-icons/bs";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

function ContactUsForm() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const submitForm = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const token = await executeRecaptcha("recaptchaToken");

      // set search params
      const params = new URLSearchParams({
        "g-recaptcha-response": token,
        contactName: e.target.contactName.value,
        contactEmail: e.target.contactEmail.value,
        phone: e.target.phone.value,
        message: e.target.message.value,
      });

      await fetch(
        `https://us-central1-dp-website-dev.cloudfunctions.net/contact?${params}`
      );

      setMessage("Form has successfully been submitted!");
      setSuccess(true);
    } catch (e) {
      setMessage("Failed to submit form! Please try again later.");
      setSuccess(false);
    }
    setLoading(false);
  };

  if (success) {
    return (
      <div className="flex flex-col items-center gap-5 mt-14">
        <BsCheck2Circle size={100} />
        <h1 className="text-ink-5 text-xl md:text-2xl font-light">{message}</h1>
      </div>
    );
  }

  return (
    <form className="flex flex-col gap-4 mt-12" onSubmit={submitForm}>
      <div className="flex flex-col md:flex-row gap-4">
        <input
          type="text"
          name="contactName"
          placeholder="Name"
          autoComplete="off"
          required
          disabled={loading}
          className="w-full px-4 py-2 lg:text-lg font-extralight border border-ink-2 focus:outline-0 focus:border-ink-3"
        />
        <input
          type="tel"
          name="phone"
          placeholder="Phone number"
          autoComplete="off"
          pattern="^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$"
          required
          disabled={loading}
          className="w-full px-4 py-2 lg:text-lg font-extralight border border-ink-2 focus:outline-0 focus:border-ink-3"
        />
      </div>
      <input
        type="email"
        name="contactEmail"
        placeholder="Email"
        autoComplete="off"
        required
        disabled={loading}
        className="w-full px-4 py-2 lg:text-lg font-extralight border border-ink-2 focus:outline-0 focus:border-ink-3"
      />
      <textarea
        name="message"
        rows="6"
        placeholder="Message"
        required
        disabled={loading}
        className="w-full px-4 py-2 lg:text-lg font-extralight border border-ink-2 focus:outline-0 focus:border-ink-3"
      ></textarea>
      <div className="flex justify-center mt-2">
        <button
          type="submit"
          className="flex justify-center items-center gap-2 py-3 px-8 rounded-full border-2 border-link text-link hover:text-ink-1 hover:bg-link text-base font-semibold tracking-wider transition-colors duration-300"
          disabled={loading}
        >
          SUBMIT
          <HiOutlineArrowSmRight size={30} />
        </button>
      </div>
      <p className="text-ink-5 font-light">{message}</p>
    </form>
  );
}

export default ContactUsForm;
