import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import Layout from "../common/Layout";
import Seo from "../common/Seo";
import Container from "../common/Container";
import ContactUsForm from "../components/ContactUsForm";

function ContactUs({ path }) {
  return (
    <Layout path={path}>
      <Seo title="Contact Us" />
      <section className="grow bg-ink-1">
        <Container>
          <div className="py-16">
            <div className="grid gap-4 max-w-2xl pb-12 mx-auto text-center">
              <p className="text-ink-4 text-lg lg:text-xl font-medium tracking-wide">
                We'd love to hear from you
              </p>
              <h2 className="text-ink-5 text-4xl lg:text-5xl font-bold leading-tight">
                CONTACT US
              </h2>
              <p className="text-ink-4 text-base lg:text-lg font-light">
                Want to find out how Data Prime can help to address problems
                specific to your business? Fill up the form below, and we'll get
                in touch shortly.
              </p>
              <GoogleReCaptchaProvider reCaptchaKey="6LdfHowfAAAAALRm3GLuYEASI5XXJjpJbq9DDQa1">
                <ContactUsForm />
              </GoogleReCaptchaProvider>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  );
}

export default ContactUs;
